import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/css/textField.css';

const EditTextCell = ({ id, data, name, product, onChange }) => {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (data) {
            setInputValue(data); // Inicializa con el valor de `data`
        }
    }, [data]);

    const isValidNumberInput = (name, value) => {
        if (name === "productlocation_stock") {
            const regex = /^\d+$/;
            return regex.test(value) || value === '';
        }
        return true; // Para otros campos, siempre es válido
    };

    const changeValue = (e) => {
        const value = e.target.value;
        if (name === "product_price") {
            handleDecimalInput(e);
        } else if (isValidNumberInput(name, value)) {
            setInputValue(value);
            if (onChange && typeof onChange === 'function') {
                onChange(id, name, value, product);
            }
        }
    };

    const handleDecimalInput = (e) => {

        const rawValue = e.target.value.replace(/[^0-9]/g, ''); // Elimina todo excepto números
        const decimalValue = (parseInt(rawValue || '0', 10) / 100).toFixed(2);

        setInputValue(decimalValue);

        if (onChange && typeof onChange === 'function') {
            onChange(id, name, decimalValue, product);
        }
    };

    return (
        <div className="custom-text-field">
            <input
                ref={inputRef}
                type="text"
                value={inputValue}
                placeholder="Enter value"
                onChange={changeValue}
            />
        </div>
    );
};

export default EditTextCell;
