
import { useEffect, useRef, useState } from "react";
import "./InfiniteAddItems.css";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

const InfiniteAddItems = ({ fields, values, onChange, name, title, requestChange }) => {

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const container = useRef();
    const firstElement = useRef();
    const [elements, setElements] = useState(0);
    const [results, setResults] = useState({});
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        let initialResult = [];
        resetData();
        if (values.length > 0) {
            values.forEach((value, index) => {
                addLineInitital(value, index + 1);
                initialResult[index + 1] = {};
                for (let e of fields) {
                    let name = e.name
                    initialResult[index + 1][name] = value[name];
                }
                setResults(initialResult);
                window[name] = initialResult;
            })
        } else {
            resetData();
            addLineInitital();
        }
    }, [requestChange])

    const resetData = () => {
        window[name] = [];
        let lineElements = document.querySelectorAll("[data-row]");
        lineElements.forEach(element => { element.remove() });
        setElements(0);
    }

    const addLineInitital = (value = null, index = null) => {
        let clone = firstElement.current.cloneNode(true);
        // if (value != null) { // TODO: ERROR REVISAR - ojo analizar esto puede estar causando conflicto con campos de tipo select.
            clone.classList.remove('no-view');
        // }
        // clone.dataset.row = index;
        clone.dataset.row = elements;
        let fields = clone.children;
        if (value !== null) {
            for (let e of fields) {
                if (e !== undefined) {
                    let field = e.children[1];
                    if(field !== undefined){
                        let name = field.name;
                        field.value = value[name];
                    }
                }
            }
        }
        // boton delete
        let btnDelete = clone.querySelector('.icon-delete');
        btnDelete.dataset.row = elements;
        btnDelete.addEventListener('click', deleteRow);

        // setElements(index);
        clone.addEventListener('change', handleFields);
        clone.addEventListener('keyup', handleInputChange);
        container.current.append(clone)
    }

    const handleFields = (e) => {
        recalculateValues();
    }

    const addLine = (e) => {
        e.preventDefault();
        // clono primer elemento
        let clone = firstElement.current.cloneNode(true);
        clone.classList.remove('no-view');
        // boton delete
        let btnDelete = clone.querySelector('.icon-delete');
        container.current.append(clone);
        let list = container.current.children;
        let size = list.length-1;
        for (let i = 0; i <= size; i++) {
            list[i].dataset.row = i;
        }
        clone.dataset.row = size;
        clone.addEventListener('change', handleFields);
        clone.addEventListener('keyup', handleInputChange);
        btnDelete.addEventListener('click', deleteRow);
        btnDelete.dataset.row = size;
        recalculateValues();
        setElements(size);
    }

    const deleteRow = (e)=>{
        e.target.parentNode.remove()
        // al eliminar linea vuelvo a crear objeto
        let data = []
        let parent = container.current.children;
        for(let i = 0; i <= parent.length; i++){
            if(parent[i]){
                if(!parent[i].classList.contains('no-view')){
                    parent[i].dataset.row = i;
                    let sons = parent[i].querySelectorAll('.bold-infinite-field');
                    let obj = {}
                    sons.forEach(field => {
                        if (field.dataset.variable === 'numeric') {
                            if(!isNaN(field.value)){
                                obj[field.name] = parseFloat(field.value);
                            }else{
                                field.value = '';
                                obj[field.name] = null
                            }
                        } else if(field.dataset.variable === 'percent'){
                            if(/^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/.test(field.value)){
                                obj[field.name] = parseFloat(field.value);
                            }else{
                                field.value = '';
                                obj[field.name] = null
                            }
                        } else {
                            obj[field.name] = field.value
                        }
                    });

                    data.push(obj);
                }
            }
        }
        setResults(data);
    }

    const recalculateValues = ()=>{
        let data = []
        let parent = container.current.children;
        for(let i = 0; i <= parent.length; i++){
            if(parent[i]){
                if(!parent[i].classList.contains('no-view')){
                    parent[i].dataset.row = i;
                    let sons = parent[i].querySelectorAll('.bold-infinite-field');
                    let obj = {}
                    sons.forEach(field => {
                        if (field.dataset.variable === 'numeric') {
                            if(!isNaN(field.value)){
                                obj[field.name] = parseFloat(field.value);
                            }else{
                                field.value = '';
                                obj[field.name] = null
                            }
                        } else if(field.dataset.variable === 'percent'){
                            if(/^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/.test(field.value)){
                                obj[field.name] = parseFloat(field.value);
                            }else{
                                field.value = '';
                                obj[field.name] = null
                            }
                        } else {
                            obj[field.name] = field.value
                        }
                    });

                    data.push(obj);
                }
            }
        }
        setResults(data);
    }

    const handleInputChange = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        if (!name.includes("_name")) {
            if(/^\d+(\.\d{1,3})?$/.test(val)){
                let formattedValue = "";
                if(e.target.value.length > 1){
                    val = val.replace(/\D/g, "");
                    val = parseFloat(val);
                    formattedValue = val ? (parseInt(val) / 100).toFixed(2) : ""; // Formatear como decimal
                }else{
                    formattedValue = val ? (parseInt(val) / 100).toFixed(2) : ""; // Formatear como decimal
                }
                e.target.value = formattedValue;
                return true;
            }else{
                e.target.value = e.target.value.slice(0, -1);
                return false
            }
        }
    };

    useEffect(() => {
        onChange(results, name);
    }, [results]);

    return (
        <>
            <div className="section-list infinite-rows">
                <div className="bold-infinite-title-section">
                    <h3>{title}</h3>
                    <button className="bold-infinite-option" onClick={addLine}>
                        <span className="material-symbols-outlined">add</span> {t('add')}</button>
                </div>

                <div ref={container}>
                    <div ref={firstElement} data-row="0" className="bold-infinite-row mb-2 no-view">
                        {
                            (fields?.map((e, index) => {
                                if (e.type === "select") {
                                    return (
                                        <div key={e.name} className="bold-box-input">
                                            <label className="bold-infinite-label">{e.label}</label>
                                            <select className="bold-infinite-field" key={e.name} name={e.name} data-variable={e.variable}>
                                                <option key="init" value="" className="bold-infinite-select-empty-option"></option>
                                                {
                                                    e.values?.map((option) => {
                                                        return <option key={option.code} value={option.code} className="bold-infinite-select-option">{option.name}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                    )
                                }
                                if (e.type === "text") {
                                    return (
                                        <div key={e.name} className="bold-box-input">
                                            <label className="bold-infinite-label">{e.label}</label>
                                            <input className="bold-infinite-field"
                                                type="text"
                                                {...register(e.name, { required: true, maxLength: 80 })}
                                                error={errors}
                                                data-variable={e.variable}
                                            />
                                        </div>
                                    )
                                }
                                if (e.type === "numeric") {
                                    return (
                                        <div key={e.name} className="bold-box-input">
                                            <label className="bold-infinite-label">{e.label}</label>
                                            <input className="bold-infinite-field"
                                                type="numeric"
                                                data-variable={e.variable}
                                            />
                                        </div>
                                    )
                                }
                                if (e.type === "percent") {
                                    return (
                                        <div key={e.name} className="bold-box-input">
                                            <label className="bold-infinite-label">{e.label}</label>
                                            <input
                                                className="bold-infinite-field"
                                                type="numeric"
                                                name={e.name}
                                                data-variable={e.type}
                                                maxLength={6}
                                                pattern="^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$"
                                                placeholder="Ex.: 10.5"
                                                onKeyDown={(e)=>{ 
                                                    if(!/^(100(\.0{1,2})?|\d{1,2}(\.\d{1,2})?)$/.test(e.target.value) ){
                                                        return false;
                                                    }
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            }))
                        }
                        <span style={{cursor:"pointer"}} className="material-symbols-outlined icon-delete">delete</span>
                    </div>
                </div>


            </div>
        </>
    )
}

export default InfiniteAddItems;